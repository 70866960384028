const mq = (content, author) => {
  return {
    "content": content,
    "author": author
  }
};

const allQuotes = [
  mq("Books don't change genres, they just advance their plot. People are the same.", "Mosele"),
  mq("Haven’t you already lifted your foot, preparing to step forward? Then you only have one option. Finish taking that step!", "Kano"),
  mq("Crying is a nosebleed of the heart.", "Kushieda"),
  mq("My happiness can only be made with my own hands! My happiness doesn’t depend on anyone but me!", "Kushieda"),
  mq("If you trip while running down a hallway, you’ll get a nosebleed. If you trip in life, you’ll cry.", "Kushieda"),
  mq("Even when the sky’s so dark you can’t see a thing, there are still stars shining out there. So if she can learn to shine brighter, we’ll be able to see her.", "Kushieda"),
  mq("I don’t want to get so caught up in searching for what I can’t see, that I lose sight of what I can.", "Kushieda"),
  mq("Adoration never leads to a balanced relationship.", "Kawashima"),
  mq("It’s difficult to like me when I’m being myself.", "Kawashima"),
  mq("Since ancient times, the dragon has been the only beast to equal the tiger. Even if you’re not by my side right now, I will leap through space and time and always be by your side. These feelings will never change.", "Takasu"),
  mq("It doesn’t matter if you grew up without parents, and it doesn’t matter if you don’t believe in God. There’s someone out there watching over you.", "Aisaka"),
  mq("It’s not about being right or being wrong. There are more important things than that. That’s why apologies and forgiveness become necessary.", "Aisaka"),
  mq("Having someone saying you’re okay as you are and being needed by that person...It was nice to have someone like that.", "Aisaka"),
  mq("Those stars look like they’re close to each other, but they’re actually very far away, aren’t they?", "Aisaka"),
  mq("The thing you wished for the most, is something you’ll never get.", "Aisaka"),
  mq("People...need to be needed by someone in order to live. If a person isn’t needed, they’re worthless!", "Kiryuu"),
  mq("It is those who possess wisdom who are the greatest fools. History has shown us this. You could say that this is the final warning from God to those who resist.", "Okabe"),
  mq("Remembering something that no one else can is a painful thing. You can’t talk to anyone about it. No one will understand you. You’ll be alone.", "Okabe"),
  mq("Keep the past, for all intents and purposes, where it is.", "Okabe"),
  mq("If you want to grant your own wish, then you should clear your own path to it.", "Okabe"),
  mq("Theories are nothing more than words. Accept what you’ve seen.", "Okabe"),
  mq("Conspiracy’s evil influence is much closer than you think, and is always ready to deceive you!", "Okabe"),
  mq("99% of perception that normal humans have are closed off. Those who don’t think of themselves are quite foolish beings.", "Okabe"),
  mq("What kind of mad scientist worries about not getting enough vegetables?", "Okabe"),
  mq("Every brilliant day should be lived for those who passed away.", "Makise"),
  mq("The passage of time depends entirely on where you’re standing. Relativity Theory...it’s so romantic. But it’s just so tragic too.", "Makise"),
  mq("A strong love for someone...a strong belief in something...a strong desire to convey something...if all those things go beyond time to create 'you,' that would be magnificent.", "Makise"),
  mq("I’ve only lived 18 years, but I don’t want to change any of them. They’re all part of my life, even the failures.", "Makise"),
  mq("Everyone is watching someone other than themselves, someone important to them.", "Makise"),
  mq("People’s feelings are memories that transcend time.", "Makise"),
  mq("Everyone gets help from someone else at some point in their lives. So someday, you should help someone too.", "Amane"),
  mq("We should work towards world peace. Like giving everyone in the world an Upa cushion.", "Shiina"),
  mq("It’s always very easy to declare that other people are idiots, but only if you forget how idiotically difficult being human is.", "Backman"),
  mq("We don’t have a plan, we just do our best to get through the day, because there’ll be another one coming along tomorrow.", "Backman"),
  mq("Do you know what the worst thing about being a parent is? That you’re always judged by your worst moments. You can do a million things right, but if you do one single thing wrong you’re forever that parent who was checking his phone in the park when your child was hit in the head by a swing.", "Backman"),
  mq("Sometimes it’s easier to live with your own anxieties if you know that no one else is happy, either.", "Backman"),
  mq("Because the terrible thing about becoming an adult is being forced to realize that absolutely nobody cares about us, we have to deal with everything ourselves now, find out how the whole world works.", "Backman"),
  mq("We give those we love nicknames, because love requires a word that belongs to us alone.", "Backman"),
  mq("Good grief, no one could cope with being newly infatuated, year after year. When you’re infatuated you can’t think about anything else, you forget about your friends, your work, your lunch. If we were infatuated all the time we’d starve to death. And being in love means being infatuated… from time to time. You have to be sensible.", "Backman"),
  mq("The truth of course is that if people really were as happy as they look on the Internet, they wouldn’t spend so much damn time on the Internet.", "Backman"),
  mq("Some people accept that they will never be free of their anxiety, they just learn to carry it.", "Backman"),
  mq("When you’ve been stuck with each other long enough it can seem like there’s no difference between no longer arguing and no longer caring.", "Backman"),
  mq("Nothing is easier for people who never do anything themselves than to criticize someone who actually makes an effort.", "Backman"),
  mq("Do you know how many men like you every single woman on the planet meets every day, who think that every thought that pops into your tiny little male brains is a lovely present you can give us?", "Backman"),
  mq("Humor is the soul’s last line of defense, and as long as we’re laughing we’re alive, so bad puns and fart jokes were their way of expressing their defiance against despair.", "Backman"),
  mq("These good men aren’t making the rules. These decent men are turning a blind eye to indecent acts.", "Bayron"),
  mq("When the leader of this kingdom treats women as property, it sets an awful precedent. People think it’s okay to do the same.", "Bayron"),
  mq("You will hear people speak of light and dark, but in my experience you must be well versed in both to find a balance.", "Bayron"),
  mq("If you drop a frog in a pot of boiling water, it will jump out. But if you stoke the fire slowly, it will allow itself to be boiled to death.", "Bayron"),
  mq("A prison is still a prison no matter how pretty the decor.", "Bayron"),
  mq("There is always fear, always doubt. The only thing that matters is that you push forward.", "Bayron"),
  mq("You know, like brilliance, I never find [being drunk] a decent excuse for being an asshole.", "Roberts"),
  mq("Never trust your future self", "Anonymous"),
  mq("Be yourself; everyone else is already taken.", "Oscar Wilde"),
  mq("I'm selfish, impatient and a little insecure. I make mistakes, I am out of control and at times hard to handle. But if you can't handle me at my worst, then you sure as hell don't deserve me at my best.", "Marilyn Monroe"),
  mq("Two things are infinite: the universe and human stupidity; and I'm not sure about the universe.", "Albert Einstein"),
  mq("So many books, so little time.", "Frank Zappa"),
  mq("A room without books is like a body without a soul.", "Marcus Tullius Cicero"),
  mq("Be who you are and say what you feel, because those who mind don't matter, and those who matter don't mind.", "Bernard M. Baruch"),
  mq("You've gotta dance like there's nobody watching, Love like you'll never be hurt, Sing like there's nobody listening, And live like it's heaven on earth.", "William W. Purkey"),
  mq("You know you're in love when you can't fall asleep because reality is finally better than your dreams.", "Dr. Seuss"),
  mq("You only live once, but if you do it right, once is enough.", "Mae West"),
  mq("Be the change that you wish to see in the world.", "Mahatma Gandhi"),
  mq("In three words I can sum up everything I've learned about life: it goes on.", "Robert Frost"),
  mq("If you want to know what a man's like, take a good look at how he treats his inferiors, not his equals.", "J.K. Rowling"),
  mq("If you tell the truth, you don't have to remember anything.", "Mark Twain"),
  mq("Friendship ... is born at the moment when one man says to another", "C.S. Lewis"),
  mq("I've learned that people will forget what you said, people will forget what you did, but people will never forget how you made them feel.", "Maya Angelou"),
  mq("A friend is someone who knows all about you and still loves you.", "Elbert Hubbard"),
  mq("Always forgive your enemies; nothing annoys them so much.", "Oscar Wilde"),
  mq("To live is the rarest thing in the world. Most people exist, that is all.", "Oscar Wilde"),
  mq("Live as if you were to die tomorrow. Learn as if you were to live forever.", "Mahatma Gandhi"),
  mq("Darkness cannot drive out darkness: only light can do that. Hate cannot drive out hate: only love can do that.", "Martin Luther King Jr."),
  mq("Without music, life would be a mistake.", "Friedrich Nietzsche"),
  mq("I am so clever that sometimes I don't understand a single word of what I am saying.", "Oscar Wilde"),
  mq("We accept the love we think we deserve.", "Stephen Chbosky"),
  mq("To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment.", "Ralph Waldo Emerson"),
  mq("Insanity is doing the same thing, over and over again, but expecting different results.", "Narcotics Anonymous"),
  mq("Twenty years from now you will be more disappointed by the things that you didn't do than by the ones you did do. So throw off the bowlines. Sail away from the safe harbor. Catch the trade winds in your sails. Explore. Dream. Discover.", "H. Jackson Brown Jr."),
  mq("It is better to be hated for what you are than to be loved for what you are not.", "Andre Gide"),
  mq("Be yourself; everyone else is already taken.", "Oscar Wilde"),
  mq("I'm selfish, impatient and a little insecure. I make mistakes, I am out of control and at times hard to handle. But if you can't handle me at my worst, then you sure as hell don't deserve me at my best.", "Marilyn Monroe"),
  mq("Two things are infinite: the universe and human stupidity; and I'm not sure about the universe.", "Albert Einstein"),
  mq("So many books, so little time.", "Frank Zappa"),
  mq("A room without books is like a body without a soul.", "Marcus Tullius Cicero"),
  mq("Be who you are and say what you feel, because those who mind don't matter, and those who matter don't mind.", "Bernard M. Baruch"),
  mq("You've gotta dance like there's nobody watching,Love like you'll never be hurt,Sing like there's nobody listening,And live like it's heaven on earth.", "William W. Purkey"),
  mq("You know you're in love when you can't fall asleep because reality is finally better than your dreams.", "Dr. Seuss"),
  mq("You only live once, but if you do it right, once is enough.", "Mae West"),
  mq("Be the change that you wish to see in the world.", "Mahatma Gandhi"),
  mq("In three words I can sum up everything I've learned about life: it goes on.", "Robert Frost"),
  mq("If you want to know what a man's like, take a good look at how he treats his inferiors, not his equals.", "J.K. Rowling"),
  mq("If you tell the truth, you don't have to remember anything.", "Mark Twain"),
  mq("I've learned that people will forget what you said, people will forget what you did, but people will never forget how you made them feel.", "Maya Angelou"),
  mq("A friend is someone who knows all about you and still loves you.", "Elbert Hubbard"),
  mq("Always forgive your enemies; nothing annoys them so much.", "Oscar Wilde"),
  mq("To live is the rarest thing in the world. Most people exist, that is all.", "Oscar Wilde"),
  mq("Live as if you were to die tomorrow. Learn as if you were to live forever.", "Mahatma Gandhi"),
  mq("Darkness cannot drive out darkness: only light can do that. Hate cannot drive out hate: only love can do that.", "Martin Luther King Jr."),
  mq("Without music, life would be a mistake.", "Friedrich Nietzsche"),
  mq("I am so clever that sometimes I don't understand a single word of what I am saying.", "Oscar Wilde"),
  mq("We accept the love we think we deserve.", "Stephen Chbosky"),
  mq("To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment.", "Ralph Waldo Emerson"),
  mq("Insanity is doing the same thing, over and over again, but expecting different results.", "Narcotics Anonymous"),
  mq("Twenty years from now you will be more disappointed by the things that you didn't do than by the ones you did do. So throw off the bowlines. Sail away from the safe harbor. Catch the trade winds in your sails. Explore. Dream. Discover.", "H. Jackson Brown Jr."),
  mq("It is better to be hated for what you are than to be loved for what you are not.", "Andre Gide"),
  mq("It is our choices, Harry, that show what we truly are, far more than our abilities.", "J.K. Rowling"),
  mq("The person, be it gentleman or lady, who has not pleasure in a good novel, must be intolerably stupid.", "Jane Austen"),
  mq("Imperfection is beauty, madness is genius and it's better to be absolutely ridiculous than absolutely boring.", "Marilyn Monroe"),
  mq("There are only two ways to live your life. One is as though nothing is a miracle. The other is as though everything is a miracle.", "Albert Einstein"),
  mq("It does not do to dwell on dreams and forget to live.", "J.K. Rowling"),
  mq("Good friends, good books, and a sleepy conscience: this is the ideal life.", "Mark Twain"),
  mq("As he read, I fell in love the way you fall asleep: slowly, and then all at once.", "John Green"),
  mq("We are all in the gutter, but some of us are looking at the stars.", "Oscar Wilde"),
  mq("It is better to remain silent at the risk of being thought a fool, than to talk and remove all doubt of it.", "Maurice Switzer"),
  mq("The fool doth think he is wise, but the wise man knows himself to be a fool.", "William Shakespeare"),
  mq("Fairy tales are more than true: not because they tell us that dragons exist, but because they tell us that dragons can be beaten.", "Neil Gaiman"),
  mq("Whenever you find yourself on the side of the majority, it is time to reform (or pause and reflect).", "Mark Twain"),
  mq("Life is what happens to us while we are making other plans.", "Allen Saunders"),
  mq("Yesterday is history, tomorrow is a mystery, today is a gift of God, which is why we call it the present.", "Bill Keane"),
  mq("I have not failed. I've just found 10,000 ways that won't work.", "Thomas A. Edison"),
  mq("A woman is like a tea bag; you never know how strong it is until it's in hot water.", "Eleanor Roosevelt"),
  mq("The opposite of love is not hate, it's indifference. The opposite of art is not ugliness, it's indifference. The opposite of faith is not heresy, it's indifference. And the opposite of life is not death, it's indifference.", "Elie Wiesel"),
  mq("It is not a lack of love, but a lack of friendship that makes unhappy marriages.", "Friedrich Nietzsche"),
  mq("The man who does not read has no advantage over the man who cannot read.", "Mark Twain"),
  mq("If you don't stand for something you will fall for anything.", "Gordon A. Eadie"),
  mq("I may not have gone where I intended to go, but I think I have ended up where I needed to be.", "douglas adams"),
  mq("I solemnly swear that I am up to no good.", "J.K. Rowling"),
  mq("Outside of a dog, a book is man's best friend. Inside of a dog it's too dark to read.", "Groucho Marx"),
  mq("I am enough of an artist to draw freely upon my imagination. Imagination is more important than knowledge. Knowledge is limited. Imagination encircles the world.", "Albert Einstein"),
  mq("I like nonsense, it wakes up the brain cells. Fantasy is a necessary ingredient in living.", "Dr. Seuss"),
  mq("Love all, trust a few, do wrong to none.", "William Shakespeare"),
  mq("Have you ever been in love? Horrible isn't it? It makes you so vulnerable. It opens your chest and it opens up your heart and it means that someone can get inside you and mess you up.", "Neil Gaiman"),
  mq("A day without sunshine is like, you know, night.", "Steve Martin"),
  mq("Never put off till tomorrow what may be done day after tomorrow just as well.", "Mark Twain"),
  mq("A reader lives a thousand lives before he dies, said Jojen. The man who never reads lives only one.", "George R.R. Martin"),
  mq("You have brains in your head. You have feet in your shoes. You can steer yourself any direction you choose. You're on your own. And you know what you know. And YOU are the one who'll decide where to go...", "Dr. Seuss"),
  mq("That which does not kill us makes us stronger.", "Friedrich Nietzsche"),
  mq("Being deeply loved by someone gives you strength, while loving someone deeply gives you courage.", "Lao Tzu"),
  mq("It is never too late to be what you might have been.", "George Eliot"),
  mq("For every minute you are angry you lose sixty seconds of happiness.", "Ralph Waldo Emerson"),
  mq("It takes a great deal of bravery to stand up to our enemies, but just as much to stand up to our friends.", "J.K. Rowling"),
  mq("I love deadlines. I love the whooshing noise they make as they go by.", "Douglas Adams"),
  mq("If you judge people, you have no time to love them.", "Mother Teresa"),
  mq("There is never a time or place for true love. It happens accidentally, in a heartbeat, in a single flashing, throbbing moment.", "Sarah Dessen"),
  mq("If you can't explain it to a six year old, you don't understand it yourself.", "Albert Einstein"),
  mq("I'm not upset that you lied to me, I'm upset that from now on I can't believe you.", "Friedrich Nietzsche"),
  mq("Love is that condition in which the happiness of another person is essential to your own.", "Robert A. Heinlein"),
  mq("There is no greater agony than bearing an untold story inside you.", "Maya Angelou"),
  mq("Anyone who thinks sitting in church can make you a Christian must also think that sitting in a garage can make you a car.", "Garrison Keillor"),
  mq("I have always imagined that Paradise will be a kind of library.", "Jorge Luis Borges"),
  mq("If you only read the books that everyone else is reading, you can only think what everyone else is thinking.", "Haruki Murakami"),
  mq("Everything you can imagine is real.", "Pablo Picasso"),
  mq("Women and cats will do as they please, and men and dogs should relax and get used to the idea.", "Robert A. Heinlein"),
  mq("We don't see things as they are, we see them as we are.", "AnaÃ¯s Nin"),
  mq("Sometimes the questions are complicated and the answers are simple.", "Dr. Seuss"),
  mq("Never trust anyone who has not brought a book with them.", "Lemony Snicket"),
  mq("\"You love me. Real or not real?\"I tell him, \"Real.\"", "Suzanne Collins"),
  mq("You can never get a cup of tea large enough or a book long enough to suit me.", "C.S. Lewis"),
  mq("We read to know we're not alone.", "William Nicholson"),
  mq("Sometimes, you read a book and it fills you with this weird evangelical zeal, and you become convinced that the shattered world will never be put back together unless and until all living humans read the book.", "John Green"),
  mq("All you need is love. But a little chocolate now and then doesn't hurt.", "Charles M. Schulz"),
  mq("Beauty is in the eye of the beholder and it may be necessary from time to time to give a stupid or misinformed beholder a black eye.", "Jim Henson"),
  mq("Of course it is happening inside your head, Harry, but why on earth should that mean that it is not real?", "J.K. Rowling"),
  mq("Today you are You, that is truer than true. There is no one alive who is Youer than You.", "Dr. Seuss"),
  mq("It is impossible to live without failing at something, unless you live so cautiously that you might as well not have lived at all - in which case, you fail by default.", "J.K. Rowling"),
  mq("Life isn't about finding yourself. Life is about creating yourself.", "George Bernard Shaw"),
  mq("To the well-organized mind, death is but the next great adventure.", "J.K. Rowling"),
  mq("I'm not afraid of death; I just don't want to be there when it happens.", "Woody Allen"),
  mq("If one cannot enjoy reading a book over and over again, there is no use in reading it at all.", "Oscar Wilde"),
  mq("If you want your children to be intelligent, read them fairy tales. If you want them to be more intelligent, read them more fairy tales.", "Albert Einstein"),
  mq("Logic will get you from A to Z; imagination will get you everywhere.", "Albert Einstein"),
  mq("Whenever I feel the need to exercise, I lie down until it goes away.", "Paul   Terry"),
  mq("I am nothing special, of this I am sure. I am a common man with common thoughts and I've led a common life. There are no monuments dedicated to me and my name will soon be forgotten, but I've loved another with all my heart and soul, and to me, this has always been enough..", "Nicholas Sparks"),
  mq("Sometimes people are beautiful.Not in looks.Not in what they say.Just in what they are.", "Markus Zusak"),
  mq("You don't get to choose if you get hurt in this world...but you do have some say in who hurts you. I like my choices.", "John Green"),
  mq("The more that you read, the more things you will know. The more that you learn, the more places you'll go.", "Dr. Seuss"),
  mq("The truth is, everyone is going to hurt you. You just got to find the ones worth suffering for.", "Bob Marley"),
  mq("Folks are usually about as happy as they make their minds up to be.", "Abraham Lincoln"),
  mq("Love looks not with the eyes, but with the mind,And therefore is winged Cupid painted blind.", "William Shakespeare"),
  mq("Do what you can, with what you have, where you are.", "Theodore Roosevelt"),
  mq("One good thing about music, when it hits you, you feel no pain.", "Bob Marley"),
  mq("There is no friend as loyal as a book.", "Ernest Hemingway"),
  mq("I find television very educating. Every time somebody turns on the set, I go into the other room and read a book.", "Groucho Marx"),
  mq("Not all of us can do great things. But we can do small things with great love.", "Mother Teresa"),
  mq("The story so far:In the beginning the Universe was created.This has made a lot of people very angry and been widely regarded as a bad move.", "Douglas Adams"),
  mq("Love is like the wind, you can't see it but you can feel it.", "Nicholas Sparks"),
  mq("Listen to the mustn'ts, child. Listen to the don'ts. Listen to the shouldn'ts, the impossibles, the won'ts. Listen to the never haves, then listen close to me... Anything can happen, child. Anything can be.", "Shel Silverstein"),
  mq("If you live to be a hundred, I want to live to be a hundred minus one day so I never have to live without you.", "Joan Powers"),
  mq("Success is not final, failure is not fatal: it is the courage to continue that counts.", "Winston S. Churchill"),
  mq("When one door of happiness closes, another opens; but often we look so long at the closed door that we do not see the one which has been opened for us.", "Helen Keller"),
  mq("It matters not what someone is born, but what they grow to be.", "J.K. Rowling"),
  mq("You don't love someone because they're perfect, you love them in spite of the fact that they're not.", "Jodi Picoult"),
  mq("Life is like riding a bicycle. To keep your balance, you must keep moving.", "Albert Einstein"),
  mq("So, this is my life. And I want you to know that I am both happy and sad and I'm still trying to figure out how that could be.", "Stephen Chbosky"),
  mq("The difference between genius and stupidity is: genius has its limits.", "Alexandre Dumas-fils"),
  mq("There is nothing I would not do for those who are really my friends. I have no notion of loving people by halves, it is not my nature.", "Jane Austen"),
  mq("Some people never go crazy. What truly horrible lives they must lead.", "Charles Bukowski"),
  mq("If you can make a woman laugh, you can make her do anything.", "Marilyn Monroe"),
  mq("We're all a little weird. And life is a little weird. And when we find someone whose weirdness is compatible with ours, we join up with them and fall into mutually satisfying weirdness and call it love true love.", "Robert Fulghum"),
  mq("Love never dies a natural death. It dies because we don't know how to replenish its source. It dies of blindness and errors and betrayals. It dies of illness and wounds; it dies of weariness, of witherings, of tarnishings.", "Anais Nin"),
  mq("What really knocks me out is a book that, when you're all done reading it, you wish the author that wrote it was a terrific friend of yours and you could call him up on the phone whenever you felt like it. That doesn't happen much, though.", "J.D. Salinger"),
  mq("I would venture to guess that Anon, who wrote so many poems without signing them, was often a woman.", "Virginia Woolf"),
  mq("Some infinities are bigger than other infinities.", "John Green"),
  mq("Do one thing every day that scares you.", "Eleanor Roosevelt"),
  mq("It is what you read when you don't have to that determines what you will be when you can't help it.", "Oscar Wilde"),
  mq("The trouble with having an open mind, of course, is that people will insist on coming along and trying to put things in it.", "Terry Pratchett"),
  mq("If I had a flower for every time I thought of you...I could walk through my garden forever.", "Alfred Tennyson"),
  mq("Time you enjoy wasting is not wasted time.", "Marthe Troly-Curtin"),
  mq("Not all those who wander are lost.", "J.R.R. Tolkien"),
  mq("Reality continues to ruin my life.", "Bill Watterson"),
  mq("One must always be careful of books and what is inside them, for words have the power to change us.", "Cassandra Clare"),
  mq("There is no pretending. I love you, and I will love you until I die, and if there is life after that, I'll love you then.", "Cassandra Clare"),
  mq("Things change. And friends leave. Life doesn't stop for anybody.", "Stephen Chbosky"),
  mq("Who are you to judge the life I live?I know I'm not perfect-and I don't live to be-but before you start pointing fingers...make sure you hands are clean!", "Bob Marley"),
  mq("The real lover is the man who can thrill you by kissing your forehead or smiling into your eyes or just staring into space.", "Marilyn Monroe"),
  mq("\"Piglet sidled up to Pooh from behind. \"Pooh!\" he whispered. \"Yes, Piglet?\" \"Nothing,\" said Piglet, taking Pooh's paw. \"I just wanted to be sure of you.\"", "A.A. Milne"),
  mq("If there's a book that you want to read, but it hasn't been written yet, then you must write it.", "Toni Morrison"),
  mq("He's like a drug for you, Bella.", "Stephenie Meyer"),
  mq("The only way out of the labyrinth of suffering is to forgive.", "John Green"),
  mq("Do not pity the dead, Harry. Pity the living, and, above all those who live without love.", "J.K. Rowling"),
  mq("A wise girl kisses but doesn't love, listens but doesn't believe, and leaves before she is left.", "Marilyn Monroe"),
  mq("The reason I talk to myself is because I'm the only one whose answers I accept.", "George Carlin"),
  mq("\"Dumbledore watched her fly away, and as her silvery glow faded he turned back to Snape, and his eyes were full of tears. \"After all this time?\" \"Always,\" said Snape.", "J.K. Rowling"),
  mq("Go to heaven for the climate and hell for the company.", "Benjamin Franklin Wade"),
  mq("When someone loves you, the way they talk about you is different. You feel safe and comfortable.", "Jess C. Scott"),
  mq("There is nothing to writing. All you do is sit down at a typewriter and bleed.", "Ernest Hemingway"),
  mq("\"I wish it need not have happened in my time,\" said Frodo. \"So do I,\" said Gandalf, \"and so do all who live to see such times. But that is not for them to decide. All we have to decide is what to do with the time that is given us.", "J.R.R. Tolkien"),
  mq("I am good, but not an angel. I do sin, but I am not the devil. I am just a small girl in a big world trying to find someone to love.", "Marilyn Monroe"),
  mq("And, when you want something, all the universe conspires in helping you to achieve it.", "Paulo Coelho"),
  mq("You may say I'm a dreamer, but I'm not the only one. I hope someday you'll join us. And the world will live as one.", "John Lennon"),
  mq("I declare after all there is no enjoyment like reading! How much sooner one tires of any thing than of a book! -- When I have a house of my own, I shall be miserable if I have not an excellent library.", "Jane Austen"),
  mq("I love mankind ... it's people I can't stand!!", "Charles M. Schulz"),
  mq("You don't forget the face of the person who was your last hope.", "Suzanne Collins"),
  mq("Classic - a book which people praise and don't read.", "Mark Twain"),
  mq("I am free of all prejudice. I hate everyone equally. ", "W.C. Fields"),
  mq("Finish each day and be done with it. You have done what you could. Some blunders and absurdities no doubt crept in; forget them as soon as you can. Tomorrow is a new day. You shall begin it serenely and with too high a spirit to be encumbered with your old nonsense.", "Ralph Waldo Emerson"),
  mq("I have never let my schooling interfere with my education.", "Mark Twain"),
  mq("The marks humans leave are too often scars.", "John Green"),
  mq("You never really understand a person until you consider things from his point of view... Until you climb inside of his skin and walk around in it.", "Harper Lee"),
  mq("Remember, we're madly in love, so it's all right to kiss me anytime you feel like it.", "Suzanne Collins"),
  mq("The books that the world calls immoral are books that show the world its own shame.", "Oscar Wilde"),
  mq("What a slut time is. She screws everybody.", "John Green"),
  mq("To die will be an awfully big adventure.", "J.M. Barrie"),
  mq("A lady's imagination is very rapid; it jumps from admiration to love, from love to matrimony in a moment.", "Jane Austen"),
  mq("Anyone who has never made a mistake has never tried anything new.", "Albert Einstein"),
  mq("It takes courage to grow up and become who you really are.", "E.E. Cummings"),
  mq("Some day you will be old enough to start reading fairy tales again.", "C.S. Lewis"),
  mq("We are what we pretend to be, so we must be careful about what we pretend to be.", "Kurt Vonnegut"),
  mq("You have to write the book that wants to be written. And if the book will be too difficult for grown-ups, then you write it for children.", "Madeleine L'Engle"),
  mq("Books are the ultimate Dumpees: put them down and they'll wait for you forever; pay attention to them and they always love you back.", "John Green"),
  mq("I'm the one that's got to die when it's time for me to die, so let me live my life the way I want to.", "Jimi Hendrix"),
  mq("We believe in ordinary acts of bravery, in the courage that drives one person to stand up for another.", "Veronica Roth"),
  mq("It's no use going back to yesterday, because I was a different person then.", "Lewis Carroll"),
  mq("But better to get hurt by the truth than comforted with a lie.", "Khaled Hosseini"),
  mq("There is nothing better than a friend, unless it is a friend with chocolate.", "Linda Grayson"),
  mq("Books are a uniquely portable magic.", "Stephen King"),
  mq("And in that moment, I swear we were infinite.", "Stephen Chbosky"),
  mq("[Dean] My dear fellow, who will let you?[oark] That's not the point. The point is, who will stop me?", "Ayn Rand"),
  mq("So it's not gonna be easy. It's going to be really hard; we're gonna have to work at this everyday, but I want to do that because I want you. I want all of you, forever, everyday. You and me... everyday.", "Nicholas Sparks"),
  mq("The truth, it is a beautiful and terrible thing, and should therefore be treated with great caution.", "J.K. Rowling"),
  mq("... a mind needs books as a sword needs a whetstone, if it is to keep its edge.", "George R.R. Martin"),
  mq("The truth is rarely pure and never simple.", "Oscar Wilde"),
  mq("Remember, if the time should come when you have to make a choice between what is right and what is easy, remember what happened to a boy who was good, and kind, and brave, because he strayed across the path of Lord Voldemort. Remember Cedric Diggory.", "J.K. Rowling"),
  mq("I believe in Christianity as I believe that the sun has risen: not only because I see it, but because by it I see everything else.", "C.S. Lewis"),
  mq("The fear of death follows from the fear of life. A man who lives fully is prepared to die at any time.", "Mark Twain"),
  mq("A lie can travel half way around the world while the truth is putting on its shoes.", "Mark Twain"),
  mq("Memories warm you up from the inside. But they also tear you apart.", "Haruki Murakami"),
  mq("Never tell the truth to people who are not worthy of it.", "Mark Twain"),
  mq("Be nice to nerds. You may end up working for them. We all could.", "Charles J. Sykes"),
  mq("Just when you think it can't get any worse, it can. And just when you think it can't get any better, it can.", "Nicholas Sparks"),
  mq("Tis better to have loved and lostThan never to have loved at all.", "Alfred Lord Tennyson"),
  mq("It's so hard to forget pain, but it's even harder to remember sweetness. We have no scar to show for happiness. We learn so little from peace.", "Chuck Palahniuk"),
  mq("Knowing yourself is the beginning of all wisdom.", "Aristotle"),
  mq("I love you as certain dark things are to be loved, in secret, between the shadow and the soul.", "Pablo Neruda"),
  mq("Be not afraid of greatness. Some are born great, some achieve greatness, and others have greatness thrust upon them.", "William Shakespeare"),
  mq("What you're supposed to do when you don't like a thing is change it. If you can't change it, change the way you think about it. Don't complain.", "Maya Angelou"),
  mq("It's the possibility of having a dream come true that makes life interesting.", "Paulo Coelho"),
  mq("Only the very weak-minded refuse to be influenced by literature and poetry.", "Cassandra Clare"),
  mq("A day without laughter is a day wasted.", "Nicolas Chamfort"),
  mq("Happiness in intelligent people is the rarest thing I know.", "Ernest Hemingway"),
  mq("A person's a person, no matter how small.", "Dr. Seuss"),
  mq("The only true wisdom is in knowing you know nothing.", "Socrates"),
  mq("Yes: I am a dreamer. For a dreamer is one who can only find his way by moonlight, and his punishment is that he sees the dawn before the rest of the world.", "Oscar Wilde"),
  mq("I don't know where there is, but I believe it's somewhere, and I hope it's beautiful.", "John Green"),
  mq("Perhaps one did not want to be loved so much as to be understood.", "George Orwell"),
  mq("\"Jesus!\" Luke exclaimed. \"Actually, it's just me,\" said Simon. \"Although I've been told the resemblance is startling.\"", "Cassandra Clare"),
  mq("Oh, I wouldn't mind, Hazel Grace. It would be a privilege to have my heart broken by you.", "John Green"),
  mq("I finally understood what true love meant...love meant that you care for another person's happiness more than your own, no matter how painful the choices you face might be.", "Nicholas Sparks"),
  mq("I became insane, with long intervals of horrible sanity.", "Edgar Allan Poe"),
  mq("Numbing the pain for a while will make it worse when you finally feel it.", "J.K. Rowling"),
  mq("Just because you have the emotional range of a teaspoon doesn't mean we all have.", "J.K. Rowling"),
  mq("It is one of the blessings of old friends that you can afford to be stupid with them.", "Ralph Waldo Emerson"),
  mq("Be careful about reading health books. Some fine day you'll die of a misprint.", "Markus Herz"),
  mq("The one you love and the one who loves you are never, ever the same person.", "Chuck Palahniuk"),
  mq("You can never be overdressed or overeducated.", "Oscar Wilde"),
  mq("Have you ever noticed how  What the hell' is always the right decision to make?", "Terry Johnson"),
  mq("Books are the quietest and most constant of friends; they are the most accessible and wisest of counselors, and the most patient of teachers.", "Charles W. Eliot"),
  mq("Those who don't believe in magic will never find it.", "Roald Dahl"),
  mq("You can't live your life for other people. You've got to do what's right for you, even if it hurts some people you love.", "Nicholas Sparks"),
  mq("An eye for an eye will only make the whole world blind.", "Mahatma Gandhi"),
  mq("If you're gonna be two-faced at least make one of them pretty.", "Marilyn Monroe"),
  mq("I would rather walk with a friend in the dark, than alone in the light.", "Helen Keller"),
  mq("So I walked back to my room and collapsed on the bottom bunk, thinking that if people were rain, I was drizzle and she was a hurricane.", "John Green"),
  mq("Until I feared I would lose it, I never loved to read. One does not love breathing.", "Harper Lee"),
  mq("I speak to everyone in the same way, whether he is the garbage man or the president of the university.", "Albert Einstein"),
  mq("A great book should leave you with many experiences, and slightly exhausted at the end. You live several lives while reading.", "William Styron"),
  mq("The truth will set you free, but first it will piss you off.", "Joe Klaas"),
  mq("Music expresses that which cannot be put into words and that which cannot remain silent", "Victor hugo"),
  mq("\"Is this the part where you start tearing off strips of your shirt to bind my wounds?\" \"If you wanted me to rip my clothes off, you should have just asked.\"", "Cassandra Clare"),
  mq("The saddest aspect of life right now is that science gathers knowledge faster than society gathers wisdom.", "Isaac Asimov"),
  mq("Creativity is knowing how to hide your sources", "C.E.M. Joad"),
  mq("'Do you hate people?' 'I don't hate them...I just feel better when they're not around.'", "Charles Bukowski"),
  mq("Fate is like a strange, unpopular restaurant filled with odd little waiters who bring you things you never asked for and don't always like.", "Lemony Snicket"),
  mq("How wonderful it is that nobody need wait a single moment before starting to improve the world.", "Anne Frank"),
  mq("A children's story that can only be enjoyed by children is not a good children's story in the slightest.", "C.S. Lewis"),
  mq("If you don't have time to read, you don't have the time (or the tools) to write. Simple as that.", "Stephen King"),
  mq("I am no bird; and no net ensnares me: I am a free human being with an independent will.", "Charlotte Bronte"),
  mq("I wish I could freeze this moment, right here, right now and live in it forever.", "Suzanne Collins"),
  mq("You will never be happy if you continue to search for what happiness consists of. You will never live if you are looking for the meaning of life.", "Albert Camus"),
  mq("The statistics on sanity are that one out of every four people is suffering from a mental illness. Look at your 3 best friends. If they're ok, then it's you.", "Rita Mae Brown"),
  mq("Life is an awful, ugly place to not have a best friend.", "Sarah Dessen"),
  mq("Happiness is when what you think, what you say, and what you do are in harmony.", "Mahatma Gandhi"),
  mq("Keep away from people who try to belittle your ambitions. Small people always do that, but the really great make you feel that you, too, can become great.", "Mark Twain"),
  mq("Never forget what you are, for surely the world will not. Make it your strength. Then it can never be your weakness. Armour yourself in it, and it will never be used to hurt you.", "George R.R. Martin"),
  mq("When we love, we always strive to become better than we are. When we strive to become better than we are, everything around us becomes better too.", "Paulo Coelho"),
  mq("I don't want to be a man. I want to be an angst-ridden teenager who can't confront his own inner demons and takes it out verbally on other people instead.", "Cassandra Clare"),
  mq("Well-behaved women seldom make history.", "Laurel Thatcher Ulrich"),
  mq("We're all human, aren't we? Every human life is worth the same, and worth saving.", "J.K. Rowling"),
  mq("Give a man a fire and he's warm for a day, but set fire to him and he's warm for the rest of his life.", "Terry Pratchett"),
  mq("Being a woman is a terribly difficult trade since it consists principally of dealings with men.", "Joseph Conrad"),
  mq("With great power... comes great need to take a nap. Wake me up later.", "Rick Riordan"),
  mq("The world is not a wish-granting factory.", "John Green"),
  mq("It's the friends you can call up at 4 a.m. that matter.", "Marlene Dietrich"),
  mq("One is loved because one is loved. No reason is needed for loving.", "Paulo Coelho"),
  mq("Everyone thinks of changing the world, but no one thinks of changing himself.", "Leo Tolstoy"),
  mq("Becoming fearless isn't the point. That's impossible. It's learning how to control your fear, and how to be free from it.", "Veronica Roth"),
  mq("If we find ourselves with a desire that nothing in this world can satisfy, the most probable explanation is that we were made for another world.", "C.S. Lewis"),
  mq("It's far better to be unhappy alone than unhappy with someone so far.", "Marilyn Monroe"),
  mq("Sometimes you have to be apart from people you love, but that doesn't make you love them any less. Sometimes you love them more.", "Nicholas Sparks"),
  mq("And above all, watch with glittering eyes the whole world around you because the greatest secrets are always hidden in the most unlikely places. Those who don't believe in magic will never find it.", "Roald Dahl"),
  mq("I don't know half of you half as well as I should like; and I like less than half of you half as well as you deserve.", "J.R.R. Tolkien"),
  mq("When I despair, I remember that all through history the way of truth and love have always won. There have been tyrants and murderers, and for a time, they can seem invincible, but in the end, they always fall. Think of it--always.", "Mahatma Gandhi"),
  mq("So we beat on, boats against the current, borne back ceaselessly into the past.", "F. Scott Fitzgerald"),
  mq("I am not pretty. I am not beautiful. I am as radiant as the sun.", "Suzanne Collins"),
  mq("There's nothing like deep breaths after laughing that hard. Nothing in the world like a sore stomach for the right reasons.", "Stephen Chbosky"),
  mq("Lock up your libraries if you like; but there is no gate, no lock, no bolt that you can set upon the freedom of my mind.", "Virginia Woolf"),
  mq("If you don't know where you're going, any road'll take you there", "George Harrison"),
  mq("Every man has his secret sorrows which the world knows not; and often times we call a man cold when he is only sad.", "Henry Wadsworth Longfellow"),
  mq("You can love someone so much...But you can never love people as much as you can miss them.", "John Green"),
  mq("Let me tell you this: if you meet a loner, no matter what they tell you, it's not because they enjoy solitude. It's because they have tried to blend into the world before, and people continue to disappoint them.", "Jodi Picoult"),
  mq("Nothing is impossible, the word itself says 'I'm possible'!", "Audrey Hepburn"),
  mq("All God does is watch us and kill us when we get boring. We must never, ever be boring.", "Chuck Palahniuk"),
  mq("Love is needing someone. Love is putting up with someone's bad qualities because they somehow complete you.", "Sarah Dessen"),
  mq("You don't have to burn books to destroy a culture. Just get people to stop reading them.", "Ray Bradbury"),
  mq("We love the things we love for what they are.", "Robert Frost"),
  mq("In vain have I struggled. It will not do. My feelings will not be repressed. You must allow me to tell you how ardently I admire and love you.", "Jane Austen"),
  mq("There is only one thing that makes a dream impossible to achieve: the fear of failure.", "Paulo Coelho"),
  mq("What is the point of being alive if you don't at least try to do something remarkable?", "John Green"),
  mq("I can't give you a sure-fire formula for success, but I can give you a formula for failure: try to please everybody all the time.", "Herbert Bayard Swope"),
  mq("Do what you feel in your heart to be right   for you'll be criticized anyway.", "Eleanor Roosevelt"),
  mq("Perhaps all the dragons in our lives are princesses who are only waiting to see us act, just once, with beauty and courage. Perhaps everything that frightens us is, in its deepest essence, something helpless that wants our love.", "Rainer Maria Rilke"),
  mq("It takes ten times as long to put yourself back together as it does to fall apart.", "Suzanne Collins"),
  mq("I refuse to answer that question on the grounds that I don't know the answer", "Douglas Adams"),
  mq("This life's hard, but it's harder if you're stupid.", "George V. Higgins"),
  mq("What a treacherous thing to believe that a person is more than a person.", "John Green"),
  mq("I would die for you. But I won't live for you.", "Stephen Chbosky"),
  mq("Sometimes I can hear my bones straining under the weight of all the lives I'm not living.", "Jonathan Safran Foer"),
  mq("Wit beyond measure is man's greatest treasure.", "J.K. Rowling"),
  mq("Maybe our favorite quotations say more about us than about the stories and people we're quoting.", "John Green"),
  mq("If you were half as funny as you think you are, you'd be twice as funny as you really are.", "H.N. Turteltaub"),
  mq("Sometimes you wake up. Sometimes the fall kills you. And sometimes, when you fall, you fly.", "Neil Gaiman"),
  mq("So, I love you because the entire universe conspired to help me find you.", "Paulo Coelho"),
  mq("Happiness is not something ready made. It comes from your own actions.", "Dalai Lama XIV"),
  mq("Death ends a life, not a relationship.", "Mitch Albom"),
  mq("Never doubt that a small group of thoughtful, committed, citizens can change the world. Indeed, it is the only thing that ever has.", "Margaret Mead"),
  mq("We are only as strong as we are united, as weak as we are divided.", "J.K. Rowling"),
  mq("Count your age by friends, not years. Count your life by smiles, not tears.", "John Lennon"),
  mq("Never love anyone who treats you like you're ordinary.", "Oscar Wilde"),
  mq("Indifference and neglect often do much more damage than outright dislike.", "J.K. Rowling"),
  mq("The weak can never forgive. Forgiveness is the attribute of the strong.", "Mahatma Gandhi"),
  mq("Most people are other people. Their thoughts are someone else's opinions, their lives a mimicry, their passions a quotation.", "Oscar Wilde"),
  mq("Peace begins with a smile..", "Mother Teresa"),
  mq("Hold fast to dreams,For if dreams dieLife is a broken-winged bird,That cannot fly.", "Langston Hughes"),
  mq("All that we see or seem is but a dream within a dream.", "Edgar Allan Poe"),
  mq("Never memorize something that you can look up.", "Albert Einstein"),
  mq("Do not read, as children do, to amuse yourself, or like the ambitious, for the purpose of instruction. No, read in order to live.", "Gustave Flaubert"),
  mq("\"We came to see Jace. Is he alright?\" \"I don't know,\" Magnus said. \"Does he normally just lie on the floor like that without moving?\"", "Cassandra Clare"),
  mq("The Christian does not think God will love us because we are good, but that God will make us good because He loves us.", "C.S. Lewis"),
  mq("All we have to decide is what to do with the time that is given us.", "J.R.R. Tolkien"),
  mq("You're going to come across people in your life who will say all the right words at all the right times. But in the end, it's always their actions you should judge them by. It's actions, not words, that matter.", "Nicholas Sparks"),
  mq("I like the night. Without the dark, we'd never see the stars.", "Stephenie Meyer"),
  mq("The only thing worse than a boy who hates you: a boy that loves you.", "Markus Zusak"),
  mq("You are, and always have been, my dream.", "Nicholas Sparks"),
  mq("You are my best friend as well as my lover, and I do not know which side of you I enjoy the most. I treasure each side, just as I have treasured our life together.", "Nicholas Sparks"),
  mq("He can run faster than Severus Snape confronted with shampoo.", "J.K. Rowling"),
  mq("So, I guess we are who we are for alot of reasons. And maybe we'll never know most of them. But even if we don't have the power to choose where we come from, we can still choose where we go from there. We can still do things. And we can try to feel okay about them.", "Stephen Chbosky"),
  mq("What lies behind us and what lies before us are tiny matters compared to what lies within us.", "Ralph Waldo Emerson"),
  mq("You talk when you cease to be at peace with your thoughts.", "Kahlil Gibran"),
  mq("Don't think or judge, just listen.", "Sarah Dessen"),
  mq("You cannot find peace by avoiding life.", "Michael Cunningham"),
  mq("In a good bookroom you feel in some mysterious way that you are absorbing the wisdom contained in all the books through your skin, without even opening them.", "Mark Twain"),
  mq("Grief does not change you, Hazel. It reveals you.", "John Green"),
  mq("Friendship is unnecessary, like philosophy, like art.... It has no survival value; rather it is one of those things which give value to survival.", "C.S. Lewis"),
  mq("I would always rather be happy than dignified.", "Charlotte Bronte"),
  mq("In the depth of winter, I finally learned that within me there lay an invincible summer.", "Albert Camus"),
  mq("Those who believe in telekinetics, raise my hand.", "Kurt Vonnegut"),
  mq("Happy Hunger Games! And may the odds be ever in your favor.", "Suzanne Collins"),
  mq("Facts do not cease to exist because they are ignored.", "Aldous Huxley"),
  mq("When you're in jail, a good friend will be trying to bail you out. A best friend will be in the cell next to you saying, 'Damn, that was fun'.", "Groucho Marx"),
  mq("Some humans would do anything to see if it was possible to do it. If you put a large switch in some cave somewhere, with a sign on it saying 'End-of-the-World Switch. PLEASE DO NOT TOUCH', the paint wouldn't even have time to dry.", "Terry Pratchett"),
  mq("Promise me you'll always remember: You're braver than you believe, and stronger than you seem, and smarter than you think.", "Carter Crocker"),
  mq("Whatever you are, be a good one.", "Abraham Lincoln"),
  mq("He's more myself than I am. Whatever our souls are made of, his and mine are the same.", "Emily Bronte"),
  mq("I hope she'll be a fool -- that's the best thing a girl can be in this world, a beautiful little fool.", "F. Scott Fitzgerald"),
  mq("If ever there is tomorrow when we're not together... there is something you must always remember. You are braver than you believe, stronger than you seem, and smarter than you think. But the most important thing is, even if we're apart... I'll always be with you.", "Carter Crocker"),
  mq("When you are courting a nice girl an hour seems like a second. When you sit on a red-hot cinder a second seems like an hour. That's relativity.", "Albert Einstein"),
  mq("If more of us valued food and cheer and song above hoarded gold, it would be a merrier world.", "J.R.R. Tolkien"),
  mq("There's a fine line between genius and insanity. I have erased this line.", "Oscar Levant"),
  mq("A good friend will always stab you in the front.", "Oscar Wilde"),
  mq("Where there is love there is life.", "Mahatma Gandhi"),
  mq("Sleep is good, he said, and books are better.", "George R. R. Martin"),
  mq("Be kind, for everyone you meet is fighting a harder battle.", "Plato"),
  mq("You can't force love, I realized. It's there or it isn't. If it's not there, you've got to be able to admit it. If it is there, you've got to do whatever it takes to protect the ones you love.", "Richelle Mead"),
  mq("Wicked people never have time for reading. It's one of the reasons for their wickedness.", "Lemony Snicket"),
  mq("I love you. I am who I am because of you. You are every reason, every hope, and every dream I've ever had, and no matter what happens to us in the future, everyday we are together is the greatest day of my life. I will always be yours. ", "Nicholas Sparks"),
  mq("Two wrongs don't make a right, but they make a good excuse.", "Thomas Szasz"),
  mq("When it comes down to it, I let them think what they want. If they care enough to bother with what I do, then I'm already better than them.", "Marilyn Monroe"),
  mq("Women are meant to be loved, not to be understood.", "Oscar Wilde"),
  mq("May you live every day of your life.", "Jonathan Swift"),
  mq("First they ignore you. Then they ridicule you. And then they attack you and want to burn you. And then they build monuments to you.", "Nicholas Klein"),
  mq("Substitute 'damn' every time you're inclined to write 'very;' your editor will delete it and the writing will be just as it should be.", "Mark Twain"),
  mq("It means 'Shadowhunters: Looking Better in Black Than the Widows of our Enemies Since 1234'.", "Cassandra Clare"),
  mq("And those who were seen dancing were thought to be insane by those who could not hear the music.", "Friedrich Nietzsche"),
  mq("I've never fooled anyone. I've let people fool themselves. They didn't bother to find out who and what I was. Instead they would invent a character for me. I wouldn't argue with them. They were obviously loving somebody I wasn't.", "Marilyn Monroe"),
  mq("I, with a deeper instinct, choose a man who compels my strength, who makes enormous demands on me, who does not doubt my courage or my toughness, who does not believe me naÃ¯ve or innocent, who has the courage to treat me like a woman.", "AnaÃ¯s Nin"),
  mq("Some women choose to follow men, and some women choose to follow their dreams. If you're wondering which way to go, remember that your career will never wake up and tell you that it doesn't love you anymore.", "Lady Gaga"),
  mq("Above all, don't lie to yourself. The man who lies to himself and listens to his own lie comes to a point that he cannot distinguish the truth within him, or around him, and so loses all respect for himself and for others. And having no respect he ceases to love.", "Fyodor Dostoevsky"),
  mq("We write to taste life twice, in the moment and in retrospect.", "Anais Nin"),
  mq("Man is the only creature who refuses to be what he is.", "Albert Camus"),
  mq("I don't want to go to heaven. None of my friends are there.", "Oscar Wilde"),
  mq("Nobody has ever measured, not even poets, how much the heart can hold.", "Zelda Fitzgerald"),
  mq("I love sleep. My life has the tendency to fall apart when I'm awake, you know?", "Ernest Hemingway"),
  mq("Who controls the past controls the future. Who controls the present controls the past.", "George Orwell"),
  mq("A clever person solves a problem. A wise person avoids it.", "Albert Einstein"),
  mq("Well, don't expect us to be too impressed. We just saw Finnick Odair in his underwear.", "Suzanne Collins"),
  mq("The best love is the kind that awakens the soul and makes us reach for more, that plants a fire in our hearts and brings peace to our minds. And that's what you've given me. That's what I'd hoped to give you forever", "Nicholas Sparks"),
  mq("It's really a wonder that I haven't dropped all my ideals, because they seem so absurd and impossible to carry out. Yet I keep them, because in spite of everything, I still believe that people are really good at heart.", "Anne Frank"),
  mq("We all die. The goal isn't to live forever, the goal is to create something that will.", "Chuck Palahniuk"),
  mq("You will always be fond of me. I represent to you all the sins you never had the courage to commit.", "Oscar Wilde"),
  mq("I have hated words and I have loved them, and I hope I have made them right.", "Markus Zusak"),
  mq("Every heart sings a song, incomplete, until another heart whispers back. Those who wish to sing always find a song. At the touch of a lover, everyone becomes a poet.", "Plato"),
  mq("Once upon a time there was a boy who loved a girl, and her laughter was a question he wanted to spend his whole life answering.", "Nicole Krauss"),
  mq("It's only after we've lost everything that we're free to do anything.", "Chuck Palahniuk"),
  mq("Doubt thou the stars are fire;Doubt that the sun doth move;Doubt truth to be a liar;But never doubt I love.", "William Shakespeare"),
  mq("Destroying things is much easier than making them.", "Suzanne Collins"),
  mq("Get busy living or get busy dying.", "Stephen King"),
  mq("The meeting of two personalities is like the contact of two chemical substances: if there is any reaction, both are transformed.", "Carl Gustav Jung"),
  mq("Science without religion is lame, religion without science is blind.", "Albert Einstein"),
  mq("I believe that imagination is stronger than knowledge. That myth is more potent than history. That dreams are more powerful than facts. That hope always triumphs over experience. That laughter is the only cure for grief. And I believe that love is stronger than death.", "Robert Fulghum"),
  mq("When someone shows you who they are believe them; the first time.", "Maya Angelou"),
  mq("You educate a man; you educate a man. You educate a woman; you educate a generation.", "Brigham Young"),
  mq("If the world were merely seductive, that would be easy. If it were merely challenging, that would be no problem. But I arise in the morning torn between a desire to improve the world and a desire to enjoy the world. This makes it hard to plan the day.", "E.B. White"),
  mq("When I look at my room, I see a girl who loves books.", "John Green"),
  mq("Chance is perhaps the pseudonym of God when he does not want to sign.", "ThÃ©ophile Gautier"),
  mq("We fell in love, despite our differences, and once we did, something rare and beautiful was created. For me, love like that has only happened once, and that's why every minute we spent together has been seared in my memory. I'll never forget a single moment of it.", "Nicholas Sparks"),
  mq("Going to church doesn't make you a Christian any more than going to a garage makes you an automobile.", "Billy Sunday"),
  mq("These woods are lovely, dark and deep,But I have promises to keep,And miles to go before I sleep,And miles to go before I sleep.", "Robert Frost"),
  mq("Reader, suppose you were an idiot. And suppose you were a member of Congress. But I repeat myself.", "Mark Twain"),
  mq("We loved with a love that was more than love.", "Edgar Allen Poe"),
  mq("All the world is made of faith, and trust, and pixie dust.", "J.M. Barrie"),
  mq("If my life is going to mean anything, I have to live it myself.", "Rick Riordan"),
  mq("It is sometimes an appropriate response to reality to go insane.", "Philip K. Dick"),
  mq("You can't stay in your corner of the Forest waiting for others to come to you. You have to go to them sometimes.", "A.A. Milne"),
  mq("Books are the perfect entertainment: no commercials, no batteries, hours of enjoyment for each dollar spent. What I wonder is why everybody doesn't carry a book around for those inevitable dead spots in life.", "Stephen King"),
  mq("Your task is not to seek for love, but merely to seek and find all the barriers within yourself that you have built against it.", "Rumi"),
  mq("This is a good sign, having a broken heart. It means we have tried for something.", "Elizabeth Gilbert"),
  mq("The fault, dear Brutus, is not in our stars, but in ourselves.", "William Shakespeare"),
  mq("I'm suppose to sit by while you date boys and fall in love with someone else, get married? Meanwhile, I'll die a little bit more every day, watching.", "Cassandra Clare"),
  mq("We should all start to live before we get too old. Fear is stupid. So are regrets.", "Marilyn Monroe"),
  mq("We have to dare to be ourselves, however frightening or strange that self may prove to be.", "May Sarton"),
  mq("Unless someone like you cares a whole awful lot,Nothing is going to get better. It's not.", "Dr. Seuss"),
  mq("Eleanor was right. She never looked nice. She looked like art, and art wasn't supposed to look nice; it was supposed to make you feel something.", "Rainbow Rowell"),
  mq("Anyone who lives within their means suffers from a lack of imagination.", "Oscar Wilde"),
  mq("Isn't it nice to think that tomorrow is a new day with no mistakes in it yet?", "L.M. Montgomery"),
  mq("I do not want people to be very agreeable, as it saves me the trouble of liking them a great deal.", "Jane Austen"),
  mq("Out beyond ideas of wrongdoing and rightdoing there is a field.I'll meet you there.When the soul lies down in that grassthe world is too full to talk about.", "Rumi"),
  mq("\"And so the lion fell in love with the lamb,\" he murmured. I looked away, hiding my eyes as I thrilled to the word. \"What a stupid lamb,\" I sighed. \"What a sick, masochistic lion.\"", "Stephenie Meyer"),
  mq("Libraries were full of ideas perhaps the most dangerous and powerful of all weapons.", "Sarah J. Maas"),
  mq("Some people care too much. I think it's called love.", "A.A. Milne"),
  mq("Let us read, and let us dance; these two amusements will never do any harm to the world.", "Voltaire"),
  mq("And now here is my secret, a very simple secret: It is only with the heart that one can see rightly; what is essential is invisible to the eye.", "Antoine de Saint-ExupÃ©ry"),
  mq("Some books should be tasted, some devoured, but only a few should be chewed and digested thoroughly.", "Sir Francis Bacon"),
  mq("It is the unknown we fear when we look upon death and darkness, nothing more.", "J.K. Rowling"),
  mq("Never laugh at live dragons.", "J.R.R. Tolkien"),
  mq("The planet is fine. The people are fucked.", "George Carlin"),
  mq("How lucky I am to have known somebody and something that saying goodbye to is so damned awful.", "Evans G. Valens"),
  mq("When I have a little money, I buy books; and if I have any left, I buy food and clothes.", "Desiderius Erasmus Roterodamus"),
  mq("Here's to books, the cheapest vacation you can buy.", "Charlaine Harris"),
  mq("Eating and reading are two pleasures that combine admirably.", "C.S. Lewis"),
  mq("Life is a book and there are a thousand pages I have not yet read.", "Cassandra Clare"),
  mq("Tell me, what is it you plan to do with your one wild and precious life?", "Mary Oliver"),
  mq("Reading is one form of escape. Running for your life is another.", "Lemony Snicket"),
  mq("I believe in everything until it's disproved. So I believe in fairies, the myths, dragons. It all exists, even if it's in your mind. Who's to say that dreams and nightmares aren't as real as the here and now?", "John Lennon"),
  mq("I might be in love with you. I'm waiting until I'm sure to tell you, though.", "Veronica Roth"),
  mq("It is the mark of an educated mind to be able to entertain a thought without accepting it.", "Aristotle"),
  mq("A guy and a girl can be just friends, but at one point or another, they will fall for each other...Maybe temporarily, maybe at the wrong time, maybe too late, or maybe forever", "Dave Matthews Band"),
  mq("Reality is merely an illusion, albeit a very persistent one.", "Albert Einstein"),
  mq("Every saint has a past, and every sinner has a future.", "Oscar Wilde"),
  mq("The secret of life, though, is to fall seven times and to get up eight times.", "Paulo Coelho"),
  mq("There are no good girls gone wrong - just bad girls found out.", "Mae West"),
  mq("Those who dream by day are cognizant of many things which escape those who dream only by night.", "Edgar Allan Poe"),
  mq("The more I know of the world, the more I am convinced that I shall never see a man whom I can really love. I require so much!", "Jane Austen"),
  mq("Hope is the thing with feathers That perches in the soul And sings the tune without the words And never stops at all.", "Emily Dickinson"),
  mq("If you expect nothing from somebody you are never disappointed.", "Sylvia Plath"),
  mq("I hope that in this year to come, you make mistakes. Because if you are making mistakes...you're Doing Something.", "Neil Gaiman"),
  mq("Never go to bed mad. Stay up and fight.", "Phyllis Diller"),
  mq("Sometimes you climb out of bed in the morning and you think, I'm not going to make it, but you laugh inside  remembering all the times you've felt that way.", "Charles Bukowski"),
  mq("You get a little moody sometimes but I think that's because you like to read. People that like to read are always a little fucked up.", "Pat Conroy"),
  mq("Don't go around saying the world owes you a living. The world owes you nothing. It was here first.", "Mark Twain"),
  mq("The world is a book and those who do not travel read only one page.", "St. Augustine"),
  mq("One, remember to look up at the stars and not down at your feet. Two, never give up work. Work gives you meaning and purpose and life is empty without it. Three, if you are lucky enough to find love, remember it is there and don't throw it away.", "Stephen Hawking"),
  mq("If we knew what it was we were doing, it would not be called research, would it?", "Albert Einstein"),
  mq("Any fool can know. The point is to understand.", "Albert Einstein"),
  mq("One day I will find the right words, and they will be simple.", "Jack Kerouac"),
  mq("I like work: it fascinates me. I can sit and look at it for hours.", "Jerome K. Jerome"),
  mq("Faithless is he that says farewell when the road darkens.", "J.R.R. Tolkien"),
  mq("I learned this, at least, by my experiment: that if one advances confidently in the direction of his dreams, and endeavors to live the life which he has imagined, he will meet with a success unexpected in common hours.", "Henry David Thoreau"),
  mq("If a cluttered desk is a sign of a cluttered mind, of what, then, is an empty desk a sign?", "Laurence J. Peter"),
  mq("That's always seemed so ridiculous to me, that people want to be around someone because they're pretty. It's like picking your breakfeast cereals based on color instead of taste.", "John Green"),
  mq("I am not young enough to know everything.", "Oscar Wilde"),
  mq("I have no special talents. I am only passionately curious.", "Albert Einstein"),
  mq("Life can only be understood backwards; but it must be lived forwards.", "SÃ¸ren Kierkegaard"),
  mq("Pain is inevitable. Suffering is optional.", "haruki murakami"),
  mq("Sarcasm is the last refuge of the imaginatively bankrupt.", "Cassandra Clare"),
  mq("I took a deep breath and listened to the old brag of my heart. I am, I am, I am.", "Sylvia Plath"),
  mq("I have decided to stick to love...Hate is too great a burden to bear.", "Martin Luther King Jr."),
  mq("Always do what you are afraid to do.", "Ralph Waldo Emerson"),
  mq("If you're reading this...Congratulations, you're alive.If that's not something to smile about,then I don't know what is.", "Chad Sugg"),
  mq("A life spent making mistakes is not only more honorable, but more useful than a life spent doing nothing.", "George Bernard Shaw"),
  mq("What's meant to be will always find a way", "Trisha Yearwood"),
  mq("Our lives begin to end the day we become silent about things that matter.", "Martin Luther King Jr."),
  mq("Make it a rule never to give a child a book you would not read yourself.", "George Bernard Shaw"),
  mq("I wanted you to see what real courage is, instead of getting the idea that courage is a man with a gun in his hand. It's when you know you're licked before you begin, but you begin anyway and see it through no matter what.- Atticus Finch", "Harper Lee"),
  mq("They say a person needs just three things to be truly happy in this world: someone to love, something to do, and something to hope for.", "Tom Bodett"),
  mq("Monsters are real, and ghosts are real too. They live inside us, and sometimes, they win.", "Stephen King"),
  mq("In a time of deceit telling the truth is a revolutionary act.", "George Orwell"),
  mq("Of all sad words of tongue or pen, the saddest are these, 'It might have been.", "John Greenleaf Whittier"),
  mq("The boy never cried again, and he never forgot what he'd learned: that to love is to destroy, and that to be loved is to be the one destroyed.", "Cassandra Clare"),
  mq("\"When his life was ruined, his family killed, his farm destroyed, Job knelt down on the ground and yelled up to the heavens, \"Why god? Why me?\" and the thundering voice of God answered, \"There's just something about you that pisses me off.\"", "Stephen King"),
  mq("We delight in the beauty of the butterfly, but rarely admit the changes it has gone through to achieve that beauty.", "Maya Angelou"),
  mq("Talent hits a target no one else can hit. Genius hits a target no one else can see.", "Arthur Schopenhauer"),
  mq("In the end, we will remember not the words of our enemies, but the silence of our friends.", "Martin Luther King Jr."),
  mq("The mind is its own place, and in itself can make a heaven of hell, a hell of heaven..", "John Milton"),
  mq("Give a girl the right shoes, and she can conquer the world.", "Bette Midler"),
  mq("The flower that blooms in adversity is the rarest and most beautiful of all.", "Walt Disney Company"),
  mq("And the day came when the risk to remain tight in a bud was more painful than the risk it took to blossom.", "Anais Nin"),
  mq("There is a theory which states that if ever anyone discovers exactly what the Universe is for and why it is here, it will instantly disappear and be replaced by something even more bizarre and inexplicable. There is another theory which states that this has already happened.", "Douglas Adams"),
  mq("There are two motives for reading a book; one, that you enjoy it; the other, that you can boast about it.", "Bertrand Russell"),
  mq("You have enemies? Good. That means you've stood up for something, sometime in your life.", "Winston Churchill"),
  mq("Faith is taking the first step even when you can't see the whole staircase.", "Martin Luther King Jr."),
  mq("Once you learn to read, you will be forever free.", "Frederick Douglass"),
  mq("To the people who look at the stars and wish, Rhys. To the stars who listen and the dreams that are answered.", "Sarah J. Maas"),
  mq("Hearts are made to be broken.", "Oscar Wilde"),
  mq("It is so hard to leave until you leave. And then it is the easiest goddamned thing in the world.", "John Green"),
  mq("Somewhere, something incredible is waiting to be known.", "Carl Sagan"),
  mq("A thing is not necessarily true because a man dies for it.", "Oscar Wilde"),
  mq("Experience is merely the name men gave to their mistakes.", "Oscar Wilde"),
  mq("Whether you think you can, or you think you can't--you're right.", "Henry Ford"),
  mq("Why did you do all this for me?' he asked. 'I don't deserve it. I've never done anything for you.' 'You have been my friend,' replied Charlotte. 'That in itself is a tremendous thing.", "E.B. White"),
  mq("Books so special and rare and yours that advertising your affection feels like a betrayal.", "John Green"),
  mq("Think of all the beauty still left around you and be happy.", "Anne Frank"),
  mq("I want to stand as close to the edge as I can without going over. Out on the edge you see all kinds of things you can't see from the center.", "Kurt Vonnegut"),
  mq("She was a girl who knew how to be happy even when she was sad. And that's important you know ", "Marilyn Monroe"),
  mq("Two people in love, alone, isolated from the world, that's beautiful.", "Milan Kundera"),
  mq("Attitude is a choice. Happiness is a choice. Optimism is a choice. Kindness is a choice. Giving is a choice. Respect is a choice. Whatever choice you make makes you. Choose wisely.", "Roy T. Bennett"),
  mq("Here's all you have to know about men and women: women are crazy, men are stupid. And the main reason women are crazy is that men are stupid.", "George Carlin"),
  mq("I did not attend his funeral, but I sent a nice letter saying I approved of it.", "Mark Twain"),
  mq("Where is human nature so weak as in the bookstore?", "Henry Ward Beecherr"),
  mq("It is hard enough to remember my opinions, without also remembering my reasons for them!", "Friedrich Nietzsche"),
  mq("Fantasy is hardly an escape from reality. It's a way of understanding it.", "Lloyd Alexander"),
  mq("You don't love someone for their looks, or their clothes, or for their fancy car, but because they sing a song only you can hear.", "oscar wilde"),
  mq("The best index to a person's character is how he treats people who can't do him any good, and how he treats people who can't fight back.", "Abigail Van Buren"),
  mq("The loneliest moment in someone's life is when they are watching their whole world fall apart, and all they can do is stare blankly.", "F. Scott Fitzgerald"),
  mq("The world is indeed full of peril, and in it there are many dark places; but still there is much that is fair, and though in all lands love is now mingled with grief, it grows perhaps the greater.", "J.R.R. Tolkien"),
  mq("There is nothing either good or bad, but thinking makes it so.", "William Shakespear"),
  mq("You think your pain and your heartbreak are unprecedented in the history of the world, but then you read. It was books that taught me that the things that tormented me most were the very things that connected me with all the people who were alive, who had ever been alive.", "James Baldwin"),
  mq("I cannot live without books.", "Thomas Jefferson"),
  mq("If at first you don't succeed, try, try again. Then quit. No use being a damn fool about it.", "W.C. Fields"),
  mq("This above all: to thine own self be true, And it must follow, as the night the day, Thou canst not then be false to any man.", "William Shakespeare"),
  mq("And by the way, everything in life is writable about if you have the outgoing guts to do it, and the imagination to improvise. The worst enemy to creativity is self-doubt.", "Sylvia Plath"),
  mq("God created war so that Americans would learn geography.", "Mark Twain"),
  mq("Think before you speak. Read before you think.", "Fran Lebowitz"),
  mq("\"Not everything is about you,\" Clary said furiously. \"Possibly,\" Jace said, \"but you do have to admit that the majority of things are.\"", "Cassandra Clare"),
  mq("Waiting is painful. Forgetting is painful. But not knowing which to do is the worst kind of suffering.", "Paulo Coelho"),
  mq("I am a man, and men do not consume pink beverages. Get thee gone woman, and bring me something brown.", "Cassandra Clare"),
  mq("Accept who you are. Unless you're a serial killer.", "Ellen DeGeneres"),
  mq("All happy families are alike; each unhappy family is unhappy in its own way.", "Leo Tolstoy"),
  mq("You know, you could live a thousand lifetimes and not deserve him.", "Suzanne Collins"),
  mq("I can't imagine a man really enjoying a book and reading it only once.", "C.S. Lewis"),
  mq("The most important thing is to enjoy your life to be happy it's all that matters.", "Audrey Hepburn"),
  mq("It is a truth universally acknowledged, that a single man in possession of a good fortune, must be in want of a wife.", "Jane Austen"),
  mq("I have loved the stars too fondly to be fearful of the night.", "Sarah   Williams"),
  mq("Never be bullied into silence. Never allow yourself to be made a victim. Accept no one's definition of your life; define yourself.", "Robert Frost"),
  mq("Do not go where the path may lead, go instead where there is no path and leave a trail.", "Ralph Waldo Emerson"),
  mq("What is a friend? A single soul dwelling in two bodies.", "Aristotle"),
  mq("For the two of us, home isn't a place. It is a person. And we are finally home.", "Stephanie Perkins"),
  mq("The most courageous act is still to think for yourself. Aloud.", "Coco Chanel"),
  mq("Courage is the most important of all the virtues because without courage, you can't practice any other virtue consistently.", "Maya Angelou"),
  mq("People aren't either wicked or noble. They're like chef's salads, with good things and bad things chopped and mixed together in a vinaigrette of confusion and conflict.", "Lemony Snicket"),
  mq("The wound is the place where the Light enters you.", "Rumi"),
  mq("\"Is this the part where you say if I hurt her, you'll kill me?\" \"No\" Simon said, \"If you hurt Clary she's quite capable of killing you herself. Possibly with a variety of weapons.\"", "Cassandra Clare"),
  mq("Hell is empty and all the devils are here.", "William Shakespeare"),
  mq("We're all going to die, all of us, what a circus! That alone should make us love each other but it doesn't. We are terrorized and flattened by trivialities, we are eaten up by nothing.", "Charles Bukowski"),
  mq("what matters most is how well you walk through the fire", "Charles Bukowski"),
  mq("I can be changed by what happens to me. But I refuse to be reduced by it.", "Maya Angelou"),
  mq("I generally avoid temptation unless I can't resist it.", "Mae West"),
  mq("Never let your sense of morals prevent you from doing what is right.", "Isaac Asimov"),
  mq("For you, a thousand times over", "Khaled Hosseini"),
  mq("Not my daughter, you bitch!", "J.K. Rowling"),
  mq("They didn't agree on much. In fact, they didn't agree on anything. They fought all the time and challenged each other ever day. But despite their differences, they had one important thing in common. They were crazy about each other.", "Nicholas Sparks"),
  mq("All little girls should be told they are pretty, even if they aren't.", "Marilyn Monroe"),
  mq("Read, read, read. Read everything -- trash, classics, good and bad, and see how they do it. Just like a carpenter who works as an apprentice and studies the master. Read! You'll absorb it.Then write. If it's good, you'll find out. If it's not, throw it out of the window.", "William Faulkner"),
  mq("I'm not saying that everything is survivable. Just that everything except the last thing is.", "John Green"),
  mq("You never have to change anything you got up in the middle of the night to write.", "Saul Bellow"),
  mq("It's not true that I had nothing on. I had the radio on.", "Marilyn Monroe"),
  mq("\"You could have had anything else in the world, and you asked for me.\" She smiled up at him. Filthy as he was, covered in blood and dirt, he was the most beautiful thing she'd ever seen. \"But I don't want anything else in the world.\"", "Cassandra Clare"),
  mq("Love is so short, forgetting is so long.", "Pablo Neruda"),
  mq("Good books don't give up all their secrets at once.", "Stephen King"),
  mq("My experience of life is that it is not divided up into genres; it's a horrifying, romantic, tragic, comical, science-fiction cowboy detective novel. You know, with a bit of pornography if you're lucky.", "Alan Moore"),
  mq("So please, oh please, we beg, we pray,Go throw your TV set away,And in its place you can installA lovely bookshelf on the wall.Then fill the shelves with lots of books.", "Roald Dahl"),
  mq("Bran thought about it. 'Can a man still be brave if he's afraid?''That is the only time a man can be brave,' his father told him.", "George R.R. Martin"),
  mq("If you can't fly then run, if you can't run then walk, if you can't walk then crawl, but whatever you do you have to keep moving forward.", "Martin Luther King Jr."),
  mq("Whoever fights monsters should see to it that in the process he does not become a monster. And if you gaze long enough into an abyss, the abyss will gaze back into you.", "Friedrich Nietzsche"),
  mq("When you're struggling with something, look at all the people around you and realize that every single person you see is struggling with something, and to them, it's just as hard as what you're going through.", "Nicholas Sparks"),
  mq("Nothing that's worthwhile is ever easy. Remember that.", "Nicholas Sparks"),
  mq("It is not in the stars to hold our destiny but in ourselves.", "William Shakespeare"),
  mq("Either write something worth reading or do something worth writing.", "Benjamin Franklin"),
  mq("Happiness is a warm puppy.", "Charles M. Schulz"),
  mq("He never went out without a book under his arm, and he often came back with two.", "Victor Hugo"),
  mq("Pain and suffering are always inevitable for a large intelligence and a deep heart. The really great men must, I think, have great sadness on earth.", "Fyodor Dostoevsky"),
  mq("Everything in the world is about sex except sex. Sex is about power.", "Oscar Wilde"),
  mq("The very essence of romance is uncertainty.", "Oscar Wilde"),
  mq("The unexamined life is not worth living.", "Socrates"),
  mq("You could rattle the stars. You could do anything, if only you dared. And deep down, you know it, too. That's what scares you most.", "Sarah J. Maas"),
  mq("If all else perished, and he remained, I should still continue to be; and if all else remained, and he were annihilated, the universe would turn to a mighty stranger.", "Emily Jane Bronte"),
  mq("Books may well be the only true magic.", "Alice Hoffman"),
  mq("Books are mirrors: you only see in them what you already have inside you.", "Carlos Ruiz ZafÃ³n"),
  mq("Try not to become a man of success. Rather become a man of value.", "Albert Einstein"),
  mq("Man is least himself when he talks in his own person. Give him a mask, and he will tell you the truth.", "Oscar Wilde"),
  mq("I love to see a young girl go out and grab the world by the lapels. Life's a bitch. You've got to go out and kick ass.", "maya angelou"),
  mq("Don't ever tell anybody anything. If you do, you start missing everybody.", "J. D. Salinger"),
  mq("I was gratified to be able to answer promptly, and I did. I said I didn't know.", "Mark Twain"),
  mq("Never allow someone to be your priority while allowing yourself to be their option.", "Mark Twain"),
  mq("Turn your wounds into wisdom.", "Oprah Winfrey"),
  mq("There is always some madness in love. But there is also always some reason in madness.", "Friedrich Nietzsche"),
  mq("No tears in the writer, no tears in the reader. No surprise in the writer, no surprise in the reader.", "Robert Frost"),
  mq("Each day means a new twenty-four hours. Each day means everything's possible again. You live in the moment, you die in the moment, you take it all one day at a time.", "Marie Lu"),
  mq("I didn't want to wake up. I was having a much better time asleep. And that's really sad. It was almost like a reverse nightmare, like when you wake up from a nightmare you're so relieved. I woke up into a nightmare.", "Ned Vizzini"),
  mq("If you remember me, then I don't care if everyone else forgets.", "Haruki Murakami"),
  mq("Don't be pushed around by the fears in your mind. Be led by the dreams in your heart.", "Roy T. Bennett"),
  mq("You gain strength, courage and confidence by every experience in which you really stop to look fear in the face. You are able to say to yourself, 'I have lived through this horror. I can take the next thing that comes along.' You must do the thing you think you cannot do.", "Eleanor Roosevelt"),
  mq("Nobody realizes that some people expend tremendous energy merely to be normal.", "Albert Camus"),
  mq("Men occasionally stumble over the truth, but most of them pick themselves up and hurry off as if nothing had happened.", "Winston S. Churchill"),
  mq("You cannot protect yourself from sadness without protecting yourself from happiness.", "Jonathan Safran Foer"),
  mq("I don't know the question, but sex is definitely the answer.", "Woody Allen"),
  mq("When you have eliminated all which is impossible, then whatever remains, however improbable, must be the truth.", "Arthur Conan Doyle"),
  mq("Many people, myself among them, feel better at the mere sight of a book.", "Jane Smiley"),
  mq("\"Remember when you tried to convince me to feed a poultry pie to the mallards in the park to see if you could breed a race of cannibal ducks?\" \"They ate it too,\" Will reminisced. \"Bloodthirsty little beasts. Never trust a duck.\"", "Cassandra Clare"),
  mq("Above all, be the heroine of your life, not the victim.", "Nora Ephron"),
  mq("Fantasy is a necessary ingredient in living, it's a way of looking at life through the wrong end of a telescope.", "Dr. Seuss"),
  mq("Two possibilities exist: either we are alone in the Universe or we are not. Both are equally terrifying.", "Arthur C. Clarke"),
  mq("Deserves it! I daresay he does. Many that live deserve death. And some that die deserve life. Can you give it to them? Then do not be too eager to deal out death in judgement. For even the very wise cannot see all ends.", "J.R.R. Tolkien"),
  mq("But luxury has never appealed to me, I like simple things, books, being alone, or with somebody who understands.", "Daphne du Maurier"),
  mq("There comes a time when the world gets quiet and the only thing left is your own heart. So you'd better learn the sound of it. Otherwise you'll never understand what it's saying.", "Sarah Dessen"),
  mq("Life is a disease: sexually transmitted, and invariably fatal.", "Neil Gaiman"),
  mq("I wonder how many people I've looked at all my life and never seen.", "John Steinbeck"),
  mq("Why fit in when you were born to stand out?", "Dr. Seuss"),
  mq("There are worse crimes than burning books. One of them is not reading them.", "Joseph Brodsky"),
  mq("To douchebags! To girls that break your heart. To the absolute fucking horror of losing your best friend because you were stupid enough to fall in love with her.", "Jamie McGuire"),
  mq("Kiss me, and you will see how important I am.", "Sylvia Plath"),
  mq("Whatever it is you're seeking won't come in the form you're expecting.", "Haruki Marukami"),
  mq("Everyone sees what you appear to be, few experience what you really are.", "Nicolas Machiavelli"),
  mq("What matters in life is not what happens to you but what you remember and how you remember it.", "Gabriel Garcia Marquez"),
  mq("That's the thing about books. They let you travel without moving your feet.", "Jhumpa Lahiri"),
  mq("Everyone should be able to do one card trick, tell two jokes, and recite three poems, in case they are ever trapped in an elevator.", "Lemony Snicket"),
  mq("All animals are equal, but some animals are more equal than others.", "George Orwell"),
  mq("It is said that your life flashes before your eyes just before you die. That is true, it's called Life.", "Terry Pratchett"),
  mq("If you're looking for sympathy you'll find it between shit and syphilis in the dictionary.", "David Sedaris"),
  mq("Remember: the time you feel lonely is the time you most need to be by yourself. Life's cruelest irony.", "Douglas Coupland"),
  mq("I have found the paradox, that if you love until it hurts, there can be no more hurt, only more love.", "Daphne Rae"),
  mq("I don't go looking for trouble. Trouble usually finds me.", "Katie McGarry"),
  mq("War is peace. Freedom is slavery. Ignorance is strength.", "George Orwell"),
  mq("I am very interested and fascinated how everyone loves each other, but no one really likes each other.", "Stephen Chbosky"),
  mq("\"That does it,\" said Jace. \"I'm going to get you a dictionary for Christmas this year.\" \"Why?\" Isabelle said. \"So you can look up 'fun.' I'm not sure you know what it means.\"", "Cassandra Clare"),
  mq("You must stay drunk on writing so reality cannot destroy you.", "Ray Bradbury"),
  mq("I have great faith in fools - self-confidence my friends will call it.", "Edgar Allan Poe"),
  mq("They love their hair because they're not smart enough to love something more interesting.", "John Green"),
  mq("It may be unfair, but what happens in a few days, sometimes even a single day, can change the course of a whole lifetime...", "Khaled Hosseini"),
  mq("Laughter is timeless. Imagination has no age. And dreams are forever.", "Walt Disney"),
  mq("Beneath the makeup and behind the smile I am just a girl who wishes for the world.", "Marilyn Monroe"),
  mq("Stop acting so small. You are the universe in ecstatic motion.", "Rumi"),
  mq("As usual, there is a great woman behind every idiot.", "John Lennon"),
  mq("Some tourists think Amsterdam is a city of sin, but in truth it is a city of freedom. And in freedom, most people find sin.", "John Green"),
  mq("The simple things are also the most extraordinary things, and only the wise can see them.", "Paulo Coelho"),
  mq("If we had to shut down for every mysterious event that at least one death could be attributed to, we'd never have time to do anything, right?", "Cecil Palmer"),
  mq("Remember: if you see something, say nothing, and drink to forget.", "Cecil Palmer"),
  mq("All hail the mighty glow cloud.", "Cecil Palmer"),
  mq("Sometimes you go through things that seem huge at the time, like a mysterious glowing cloud devouring your entire community...And then the glow cloud moves on. And you move on.", "Cecil Palmer"),
  mq("Night Vale is an ancient place. Full of history and secrets, as we were reminded today. But it is also a place of the present moment, full of life, and of us.", "Cecil Palmer"),
  mq("Close your eyes. Let my words wash over you. You are safe now.", "Cecil Palmer"),
  mq("The moon’s weird though, right? It’s there, and there, and then suddenly it’s not. And it seems to be pretty far up. Is it watching us?", "Cecil Palmer"),
  mq("Existence as an unchanging plain with horizons of birth and death in the faint distance.", "Cecil Palmer"),
  mq("We do not have answers. I am not certain we even have questions.", "Cecil Palmer"),
  mq("Silence is golden. Words are vibrations. Thoughts are magic.", "Cecil Palmer"),
  mq("There are many things I wish I could remember.", "Cecil Palmer"),
  mq("A life of pain is the pain of life, and you can never escape it.", "Cecil Palmer"),
  mq("Weird at last, weird at last, god almighty weird at last.", "Cecil Palmer"),
  mq("I will place within some of you questions. Within others, I will place answers. These questions and these answers will not always align. The questions I provide may have no answers, and the answers I provide may have no questions.", "Pyramid"),
  mq("Death will be the last action you undertake.", "Pyramid"),
  mq("Regret nothing, until it is too late. Then, regret everything.", "Cecil Palmer"),
  mq("Penalties for overdue library books has skyrocketed to 50 cents per day, and, after 30 days, jaw mutilation.", "Cecil Palmer"),
  mq("We have nothing to fear except ourselves. We are unholy, awful people.", "Cecil Palmer"),
  mq("You curl under a blanket, protecting your body from the world, excepting a few clever spiders. And you are listening. Hearing me.", "Cecil Palmer"),
  mq("While the future is fast coming for you, it always flinches first and settles in as the gentle present.", "Cecil Palmer"),
  mq("Some mysteries aren't questions to be answered, but just a kind of opaque fact. A thing which exists to be not known.", "Cecil Palmer"),
  mq("Bananas are hardly that slippery. But watch your step anyway.", "Cecil Palmer"),
  mq("You too have survived. Survived everything up to this moment.", "Cecil Palmer"),
  mq("Your existence is not impossible. But it's also not very likely.", "Cecil Palmer"),
  mq("Monday will not harm you, but you should stack up on latex gloves nonetheless.", "Cecil Palmer"),
  mq("Let’s not dwell on our corpse strewn past. Let’s celebrate our corpse strewn future!", "Cecil Palmer"),
  mq("Hang a map of a place you will never go on your living room wall. Draw new streets. Tear off bodies of water. Wait for news crews to arrive.", "Cecil Palmer"),
  mq("Subway, eat fresh. Eat so terribly, terribly fresh. Terribly, awesomely, gruesomely, terrifyingly fresh.", "Cecil Palmer"),
  mq("Ask your doctor if right is left for you.", "Cecil Palmer"),
  mq("Time is like wax, dripping from a candle flame. In the moment, it is molten and falling, with the capability to transform into any shape. Then the moment passes, and the wax hits the table top and solidifies into the shape it will always be. It becomes the past, a solid single record of what happened, still holding in its wild curves and contours the potential of every shape it could have held.", "Cecil Palmer"),
  mq("Perfection is not real. Perfection is not human...those imperfections in our reality are the seams and cracks into which our outsized love can seep and pool.", "Cecil Palmer"),
  mq("None of us are talking to the teenagers that exist now, but talking back to the teenager we ourselves once were – all stupid mistakes and lack of fear.", "Cecil Palmer"),
  mq("Are we living a life that is safe from harm? Of course not. We never are. But that’s not the right question. The question is are we living a life that is worth the harm?", "Cecil Palmer"),
  mq("I like my coffee like I like my nights: dark, endless and impossible to sleep through.", "Cecil Palmer"),
  mq("If you would like to learn more about starting a militia, simply learn to be a true American.", "Cecil Palmer"),
  mq("The living tell the dying not to leave, and the dying do not listen. The dying tell us not to be sad for them, and we do not listen. The dialogue between the living and the dead is full of misunderstanding and silence.", "Cecil Palmer"),
  mq("Dying is easy, young man, Living is harder.", "George Washington"),
  mq("Immigrants...we get the job done.", "Alexander Hamilton and Marquis de Lafayette"),
  mq("I'm not throwing away my shot", "Alexander Hamilton"),
  mq("There's a million things I haven't done. Just you wait.", "Alexander Hamilton"),
  mq("In New York you can be a new man.", "Aaron Burr and Company"),
  mq("If you stand for nothing, Burr, what will you fall for?", "Alexander Hamilton"),
  mq("I'm just like my country. I'm young, scrappy, and hungry.", "Alexander Hamilton"),
  mq("This is not a moment. It's the movement.", "Alexander Hamilton"),
  mq("I may not live to see our glory. But I will gladly join the fight. And when our children tell our story, they'll tell the story of tonight.", "Alexander Hamilton"),
  mq("Raise a glass to freedom, something they can never take away.", "John Laurens"),
  mq("When I meet Thomas Jefferson, I'll compel him to include women in the sequel. Work!", "Angelica Schuyler"),
  mq("Look around, look around, at how lucky we are to be alive right now.", "Eliza Hamilton"),
  mq("Martha Washington named her feral tomcat after him.", "Aaron Burr"),
  mq("I'm just saying if you really loved me you would share him.", "Angelica Schuyler"),
  mq("I will never be satisfied.", "Angelica Schuyler"),
  mq("Love doesn't discriminate between the sinners and the saints. It takes and it takes and it takes. And we keep loving anyway.", "Aaron Burr"),
  mq("Look at where you are. Look at where you started. The fact that you're alive is a miracle. Just stay alive, that would be enough.", "Eliza Hamilton"),
  mq("Who lives, who dies, who tells your story?", "George Washington"),
  mq("Life, liberty, and the pursuit of happiness. We fought for these ideals we shouldn't settle for less.", "Thomas Jefferson"),
  mq("I wanna be in the room where it happens.", "Aaron Burr"),
  mq("If there's a fire you're trying to douse, you can't put it out from inside the house!", "Thomas Jefferson"),
  mq("Everything's legal in New Jersey.", "Alexander and Philip Hamilton"),
  mq("Can we get back to politics please?", "Thomas Jefferson"),
  mq("My love, take your time. I'll see you on the other side.", "Alexander Hamilton"),
  mq("I put myself back in the narrative. I stop wasting time on tears. I live another fifty years. It's not enough.", "Eliza Hamilton"),
  mq("I am the one thing in life I can control. I am inimitable, I am an original.", "Aaron Burr"),
  mq("Legacy. What is a legacy? It’s planting seeds in a garden you never get to see.", "Alexander Hamilton"),
  mq("I’m past patiently waitin’ I’m passionately smashin’ every expectation. Every action’s an act of creation!", "Alexander Hamilton"),
  mq("We push away what we can never understand. We push away the unimaginable.", "Angelica Schuyler"),
  mq("God help and forgive me, I wanna build something that’s gonna outlive me.", "Alexander Hamilton"),
  mq("When you got skin in the game, you stay in the game. But you don’t get a win unless you play in the game.", "Alexander Hamilton"),
  mq("And when you said ‘Hi,’ I forgot my dang name, set my heart aflame, ev’ry part aflame", "Angelica Schuyler"),
  mq("Happiness is a bagel", "Anonymous"),
  mq("When you start at a new coffeehouse, don't move the cups.", "Anonymous")
]

const randomCustomQuote = () => allQuotes[Math.floor(Math.random() * allQuotes.length)];

export default randomCustomQuote;
