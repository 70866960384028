import React from "react";
import QuoteWrapper from "./components/QuoteWrapper/index";

class Home extends React.Component {
  render() {
    return (
      <QuoteWrapper />
    );
  }
}

export default Home;
